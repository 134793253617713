import { faAngleDown, faAngleLeft, faAngleUp, faFileUpload, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { addUsers, listSingleUser } from '../../Redux/User/action';
import Users from './users';
import Select from 'react-select';
import { getGroups, listGroups } from '../../Redux/Groups/action';
import { toast, ToastContainer } from 'react-toastify';

const AddNewUser = (props) => {
    const [active, setActive] = useState("")
    const [options, setOptions] = useState({});
    const [groups, setGroups] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const { edit, id } = props;
    const imageInput = useRef();
    useEffect(() => {
        if (id) {
            listSingleUser(id)((response) => {
                if (response && response.status) {
                    formik.setValues({
                        ...formik.values,
                        name: response?.data?.results[0].name,
                        email: response?.data?.results[0].email,
                        phone: response?.data?.results[0].phone,
                        groups: response?.data?.results[0].groups,
                        username: response?.data?.results[0].username,
                        country_code: response?.data?.results[0].country_web_code,
                        usertype: response?.data?.results[0].is_admin ? 'admin' : response?.data?.results[0].rm_admin ? 'rm_admin' : 'customer',
                        userimageurl: response?.data?.results[0].image,
                    })
                    setGroups(response?.data?.results[0].groups.map((val) => val.value))
                }
            });
        }
    }, [id])
    useEffect(() => {

        listGroups()((response) => {
            if (response && response.status) {
                var datas = [];
                response?.data?.results.map((value) => {
                    datas.push({ value: value?.pk, label: value?.name })
                })
                setOptions(datas)
            }
        });

    }, [])
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),

        email: Yup.string().email()
            .required('Required'),
        // phone: Yup.number().max(999999999999999)
        //     .required('Required'),
        phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Enter a valid mobile number')
            .required('Required'),
        username: Yup.string()
            .required('Required'),
        userimageurl: Yup.string()
            .required('Required'),
        //   groups: Yup.string()
        //   .required('Required'),
        // password: Yup.string()
        //     .required('Required'),
        // confirm_password: Yup.string()
        //     .required('Required'),


    });
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            username: "",
            userimage: "",
            groups: "",
            userimageurl: "",
            password: "",
            confirm_password: "",
            country_code: "",
            usertype: "admin",
        },
        validationSchema,
        validate: (values) => {
            const errors = {};
            if (edit) {
                if (values.password != '' || values.confirm_password != '') {
                    if (values.password !== values.confirm_password) {
                        errors.password = 'Password mismatch';
                        errors.confirm_password = 'Password mismatch';
                    }
                }
            } else {
                if (values.password == '') {
                    errors.password = 'Required';
                }
                if (values.confirm_password == '') {
                    errors.confirm_password = 'Required';
                }
                if (values.password !== values.confirm_password) {
                    errors.password = 'Password mismatch';
                    errors.confirm_password = 'Password mismatch';
                }

            }

            return errors;
        },
        onSubmit: (values) => {
            if (groups && groups.length == 0) {
                toast.warning("Please select a group", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setDisableBtn(true)
                let formData = new FormData();
                if (edit)
                    formData.append('user_id', id);

                formData.append('name', values.name);
                formData.append('username', values.username);
                if (values.password)
                    formData.append('password', values.password);
                formData.append('phone', values.phone);
                if (values.userimage != '')
                    formData.append('image', values.userimage);
                formData.append('email', values.email);
                formData.append('country_web_code', values.country_code);
                if (values?.usertype == 'admin') {
                    formData.append('is_admin', true);
                }
                else {
                    formData.append('rm_admin', true);
                }
                console.log('on here');
                formData.append('groups', JSON.stringify(groups.map(group => group)));
                addUsers({ formData })((response) => {
                    if (response && response.status) {
                        setActive('users')
                        toast.success("User created successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    else {
                        setDisableBtn(false)
                        // console.log(response?.response?.data?.message ? response?.response?.data?.message : '');
                        // console.log(response?.response?.data?.errors?.email[0]);
                        toast.error(response?.response?.data?.message ? response?.response?.data?.message : response?.response?.data?.errors?.email[0] ? response?.response?.data?.errors?.email[0] : "Something went wrong", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                });
            }
        },
    });


    const openFileUploader = () => {
        imageInput.current.click();
    }
    const handleChangeImage = (e) => {
        formik.setValues({ ...formik.values, userimage: e.target.files[0], userimageurl: URL.createObjectURL(e.target.files[0]) })
    }
    const deleteSelectedImage = (e) => {
        formik.setValues({ ...formik.values, userimage: '', userimageurl: '' })
    }
    const handleOnSelectChange = ({ name, value }) => {
        formik.setValues({ ...formik.values, [name]: value.value })
        setGroups(value.map((val) => val.value))
    }

    console.log(formik.errors);
    return (
        <Fragment>
            <ToastContainer style={{ zIndex: 99999 }} />
            {active === "users" ? <Users /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("users")}
                            style={{ marginRight: "12px", }}
                        />Users</h2>
                        <button className='button' onClick={() => setActive("users")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className='input-text'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='name'
                                    isValid={formik.touched.name && !formik.errors.name}
                                    isInvalid={!!formik.errors.name}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='input-text'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name='email'
                                    isValid={formik.touched.email && !formik.errors.email}
                                    isInvalid={!!formik.errors.email}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='input-text'>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='phone'
                                    isValid={formik.touched.phone && !formik.errors.phone}
                                    isInvalid={!!formik.errors.phone}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group className='input-text'>
                                <Form.Label>User Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='username'
                                    isValid={formik.touched.username && !formik.errors.username}
                                    isInvalid={!!formik.errors.username}
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='input-text'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type={showPassword ? 'input' : 'password'}
                                    name='password'
                                    isValid={formik.touched.password && !formik.errors.password}
                                    isInvalid={!!formik.errors.password} value={formik.values.password}
                                    onChange={formik.handleChange}
                                    placeholder=""

                                />
                                <div className='eye_button'>
                                    {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />

                                    </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>}
                                </div>
                                <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group className='input-text'>
                                <Form.Label>Confirm password</Form.Label>
                                <Form.Control
                                    type={showConfirmPassword ? 'input' : 'password'}
                                    name='confirm_password'
                                    isValid={formik.touched.confirm_password && !formik.errors.confirm_password}
                                    isInvalid={!!formik.errors.confirm_password} value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    placeholder=""

                                />
                                <div className='eye_button'>
                                    {showConfirmPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { toggleConfirmPasswordVisibility() }} viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />

                                    </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { toggleConfirmPasswordVisibility() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>}
                                </div>
                                <Form.Control.Feedback type="invalid">{formik.errors.confirm_password}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-text'>
                                <Form.Label>Country code</Form.Label>
                                <Form.Control type="text" name='country_code' value={formik.values.country_code} onChange={formik.handleChange} placeholder="" />
                            </Form.Group>
                            <div className='col-12 mb-2'>
                                <Form.Group className='input-text'>
                                    <Form.Label>User Type</Form.Label>
                                    <Form.Select name='usertype' value={formik.values.usertype} onChange={formik.handleChange} placeholder="">
                                        <option value='admin'>Admin</option>
                                        <option value='rm_admin'>RM Admin</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Label className='fw-bold'>Groups</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isMulti
                                    value={formik.values?.groups}
                                    name="groups"
                                    options={options}
                                    onChange={(e) => { handleOnSelectChange({ name: 'groups', value: e }) }}
                                    placeholder="Select User Group..."
                                />



                            </div>
                            <div className='add-new-product'>
                                <div className='add-new'>
                                    <div className='add-new-file'>
                                        <input type="file" className='d-none' ref={imageInput} onChange={(e) => handleChangeImage(e)} />
                                        <Form.Group className='input-text mt-2'>
                                            <Form.Label>User Image</Form.Label>
                                        </Form.Group>
                                        <div className='file-images d-flex flex-row align-items-center justify-content-start flex-wrap ' style={{ overFlowX: 'scroll' }}>

                                            <div className='ms-2' >
                                                <img src={formik?.values?.userimageurl ? formik?.values?.userimageurl : require("../../assets/images/upload.png")} alt="upload" onClick={() => openFileUploader()} />
                                                <button type='button'><FontAwesomeIcon icon={faXmarkCircle} onClick={() => deleteSelectedImage()} /></button>
                                                {formik.errors?.userimageurl &&
                                                    <div className='text-danger'>* Required</div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='buttons'>
                                <button className='button' type='submit' disabled={disableBtn}>Save</button>
                                <button className='button clear-btn' onClick={formik.resetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewUser