
export const BACKEND_URL = 'https://api.stikcon.com/api/'
export const IMAGE_URL = "https://api.stikcon.com"
// export const BACKEND_URL = 'https://stikcon.aventusinformatics.com/api/'
// export const IMAGE_URL = "https://stikcon.aventusinformatics.com"
// export const BACKEND_URL = 'http://192.168.18.110:8002/api/'
// export const IMAGE_URL = "http://192.168.18.110:8002/api/"


//dashboard
export const LIST_DASHBOARD_DATAS_URL = 'dashboarddashboard/get-dashboards'

//rooms
export const CREATE_CATEGORY_ROOMS_URL = 'category/rooms/create-or-update-category-rooms'
export const LIST_CATEGORY_ROOMS_URL = 'category/rooms/get-all-category-rooms'
export const DELETE_CATEGORY_ROOMS_URL = 'category/rooms/destroy-category-rooms'

//styles
export const CREATE_CATEGORY_STYLE_URL = 'category/styles/create-or-update-category-styles'
export const LIST_CATEGORY_STYLE_URL = 'category/styles/get-all-category-styles'
export const DELETE_CATEGORY_STYLE_URL = 'category/styles/destroy-category-styles'


//feels
export const CREATE_CATEGORY_FEELS_URL = 'category/feels/create-or-update-category-feels'
export const LIST_CATEGORY_FEELS_URL = 'category/feels/get-all-category-feels'
export const DELETE_CATEGORY_FEELS_URL = 'category/feels/destroy-category-feels'


//interior
export const CREATE_CATEGORY_INTERIOR_URL = 'category/interior/create-or-update-category-interior'
export const LIST_CATEGORY_INTERIOR_URL = 'category/interior/get-all-category-interior'
export const DELETE_CATEGORY_INTERIOR_URL = 'category/interior/destroy-category-interior'


//Colour Preference
export const CREATE_CATEGORY_COLOR_PREFERENCE_URL = 'category/colour-preference/create-or-update-category-colour-preference'
export const LIST_CATEGORY_COLOR_PREFERENCE_URL = 'category/colour-preference/get-all-category-colour-preference'
export const DELETE_CATEGORY_COLOR_PREFERENCE_URL = 'category/colour-preference/destroy-category-colour-preference'


//Colours
export const CREATE_CATEGORY_COLOR_URL = 'category/colour/create-or-update-category-colour'
export const LIST_CATEGORY_COLOR_URL = 'category/colour/get-all-category-colour'
export const DELETE_CATEGORY_COLOR_URL = 'category/colour/destroy-category-colour'


//Meterial
export const CREATE_CATEGORY_METERIAL_URL = 'category/material/create-or-update-category-material'
export const LIST_CATEGORY_METERIAL_URL = 'category/material/get-all-category-material'
export const DELETE_CATEGORY_METERIAL_URL = 'category/material/destroy-category-material'


//furniture
export const CREATE_CATEGORY_FURNITURE_URL = 'category/furniture/create-or-update-category-furniture'
export const LIST_CATEGORY_FURNITURE_URL = 'category/furniture/get-all-category-furniture'
export const DELETE_CATEGORY_FURNITURE_URL = 'category/furniture/destroy-category-furniture'


//room shape
export const CREATE_CATEGORY_ROOM_SHAPE_URL = 'category/room-shape/create-or-update-category-room-shape'
export const LIST_CATEGORY_ROOM_SHAPE_URL = 'category/room-shape/get-all-category-furniture-room-shape'
export const DELETE_CATEGORY_ROOM_SHAPE_URL = 'category/room-shape/destroy-category-furniture-room-shape'


//room layouts
export const CREATE_CATEGORY_ROOM_LAYOUT_URL = 'category/room-layout/create-or-update-category-room-layout'
export const LIST_CATEGORY_ROOM_LAYOUT_URL = 'category/room-layout/get-all-category-room-layout'
export const LIST_ALL_ROOM_LAYOUT_URL = 'category/room-layout/get-all-category-room-layout-listing'
export const DELETE_CATEGORY_ROOM_LAYOUT_URL = 'category/room-layout/destroy-category-room-layout'


//products routes
export const CREATE_PRODUCT_URL = 'product/products/create-or-update-products'
export const LIST_PRODUCT_URL = 'product/products/get-products'
export const DELETE_PRODUCT_URL = 'product/products/destroy-products'
export const DELETE_PRODUCT_IMAGE_URL = 'product/products/destroy-products-images'


//Master settings routes
export const CREATE_MASTER_SETTINGS_URL = 'mastersettings/MasterSettings/create-or-update-mastersettingsfields'
export const LIST_MASTER_SETTINGS_URL = 'mastersettings/MasterSettings/get-master-settings-fields'
export const DELETE_MASTER_SETTINGS_URL = 'mastersettings/MasterSettings/destroy-master-settings-fields'


//purchase routes
export const LIST_PURCHASE_URL = 'packagescustomer-package-/get-customer-package'


//report routes
export const LIST_REPORT_URL = 'order/reports/get-reports'
export const DOWNLOAD_REPORT_URL = 'order/order/pdf-generate'

//customer routes
export const LIST_CUSTOMER_URL = 'auth/admin/listing-users'


//user routes
export const LIST_USER_URL = 'auth/admin/listing-users'
export const CREATE_USER_URL = 'auth/admin/create-or-update-admin'
export const DELETE_USER_URL = 'auth/admin/destroy-admins'


//packages routes
export const LIST_PACKAGES_URL = 'packagespackage-/get-package'
export const CREATE_PACKAGES_URL = 'packagespackage-/create-or-update-package'
export const DELETE_PACKAGES_URL = 'packagespackage-/destroy-package'


//designs routes
export const LIST_DESIGNS_URL = 'interior/custom-customer-get-my-interior-designs'
// export const CREATE_PACKAGES_URL = 'packagespackage-/create-or-update-package'
// export const DELETE_PACKAGES_URL = 'packagespackage-/destroy-package'

//designs routes
export const CREATE_UPDATE_MAIN_DESIGNS_URL = 'homeplan/HomePlan/create-or-update-homeplan'
export const LIST_MAIN_DESIGNS_URL = 'homeplan/HomePlan/list-homeplan'
export const SINGLE_MAIN_DESIGNS_URL = 'homeplan/HomePlan/single-details-homeplan'
export const DELETE_MAIN_DESIGNS_URL = 'homeplan/HomePlan/destroy-homeplan'

//typology

export const LIST_TYPOLOGY_CATEGORIES_URL = 'interior/filter-interior-admin-customer'
export const LIST_TYPOLOGY_URL = 'interior/custom-admin-get-all-admin-interior-designs'
export const CREATE_TYPOLOGY_URL = 'interior/custom-admin-create-or-update-interior-admin'
export const DELETE_TYPOLOGY_URL = 'interior/custom-admin-destroy-interior-admin'
export const LIST_TYPOLOGY_DATA_URL = 'interior/filter-interior-admin'


//faq

export const CREATE_FAQ_URL = 'faq/Faq/faq-create-update'
export const LIST_FAQ_URL = 'faq/Faq/Get-faq-listing'
export const LIST_SINGLE_FAQ_URL = 'faq/Faq/Get-faq-single-listing'
export const DELETE_FAQ_URL = 'faq/Faq/faq-destroy'

//roles
export const GET_ALL_PERMISSIONS_URL = 'user/get-permissions'
export const CREATE_ROLE_URL = 'user/roles/create-or-update-role'
export const GET_ROLES_URL = 'user/roles/get-roles'
export const GET_SINGLE_ROLE_URL = 'user/roles/get-role-details'
export const DELETE_ROLE_URL = 'user/roles/destroy-role'

//groups
export const CREATE_GROUP_URL = 'user/groups/create-or-update-group'
export const GET_GROUPS_URL = 'user/groups/get-groups'
export const GET_SINGLE_GROUP_URL = 'user/groups/get-group-details'
export const DELETE_GROUP_URL = 'user/groups/destroy-group'


//property type
export const GET_PROPERTY_TYPE_URL = 'homeplan/property/get-property'
export const CREATE_PROPERTY_TYPE_URL = 'homeplan/property/create-or-update-property'
export const DELETE_PROPERTY_TYPE_URL = 'homeplan/property/destroy-property'

//directions
export const GET_DIRECTIONS_URL = 'homeplan/directions/get-direction'
export const GET_DIRECTIONS_DROPDOWN_URL = 'homeplan/directions/get-dropdown-direction'
export const CREATE_DIRECTIONS_URL = 'homeplan/directions/create-or-update-direction'
export const DELETE_DIRECTIONS_URL = 'homeplan/directions/destroy-direction'



//login
export const LOGIN_URL = 'auth/login-user-admin'

//active user
export const ACTIVE_INACTIVE_USERS = 'auth/admin/active-or-inactive-users'

//logout
export const LOGOUT_URL = 'user/token/logout-userprofile'

//forgot password
export const FORGOT_PASSWORD_URL = 'auth/password/forget-password'

//reset password
export const RESET_PASSWORD_URL = 'auth/password/reset/password'

//testimonials
export const LIST_TESTIMONIALS_URL = 'our-designersdesigners/get-our-testimonial'
export const CREATE_TESTIMONIALS_URL = 'our-designersdesigners/create-or-update-our-testimonial'
export const DELETE_TESTIMONIALS_URL = 'our-designersdesigners/destroy-our-testimonial'


//testimonials
export const LIST_OUR_DESIGNERS_URL = 'our-designersdesigners/get-our-designers'
export const CREATE_OUR_DESIGNERS_URL = 'our-designersdesigners/create-or-update-our-designers'
export const DELETE_OUR_DESIGNERS_URL = 'our-designersdesigners/destroy-our-designers'
export const DELETE_OUR_DESIGNERS_PORTFOLIO_URL = 'our-designersdesigners/destroy-designers-portfolio'


//contact us
export const LIST_CONTACT_US_URL = 'faq/contactus/get-contactus-listing'

//settings
export const CREATE_BROCHURE_URL = 'faq/brochure/brochure-create-update'
export const LIST_BROCHURE_URL = 'faq/brochure/get-brochure-listing'

//solutions  

export const GET_SINGLE_SOLUTION_URL = 'homeplan/solution/get-solution'
export const CREATE_SOLUTION_URL = 'homeplan/solution/create-or-update-solution'
export const DELETE_SOLUTION_URL = 'homeplan/solution/destroy-solution'

//notification
export const GET_NOTIFICATION_LIST = 'notificationNotification/get-listing-notification/';
export const GET_NOTIFICATION_DELETE = 'notificationNotification/destroy-notification/';
export const MARK_ALL_AS_READ_NOTIFICATION_URL = 'notificationNotification/disable-notification/';