import { LIST_CONTACT_US_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listContactUs = (search,page,limit) => onResponse => {
    try {
        axiosInstance.get(LIST_CONTACT_US_URL + '?search=' + search + '&page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};