import { useFormik } from 'formik';
import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { userLogin } from '../../Redux/Auth/action';
import loginimg from '../../assets/images/signup-image.jpg';
import { toast, ToastContainer } from 'react-toastify';
import { useState } from 'react';
import Svgloader from '../SvgLoader';

const Login = () => {
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Required'),

        password: Yup.string()
            .required('Required'),

    });
    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",


        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values)
            setLoading(true)
            let formData = new FormData();

            formData.append('username', values.username);
            formData.append('password', values.password);

            userLogin({ formData })((response) => {
                if (response && response.status) {
                    console.log(response?.data);
                    setLoading(false)
                    localStorage.setItem('token', response?.data?.token)
                    localStorage.setItem('refresh_token', response?.data?.refresh)
                    localStorage.setItem('user', JSON.stringify(response?.data?.user))
                    localStorage.setItem('permissions', JSON.stringify(response?.data?.user?.permissions))
                    setTimeout(() => {
                        navigate('/')

                    }, 200);
                }
                else {
                    setLoading(false)
                    toast.error(response?.response?.data?.message ? response?.response?.data?.message : 'Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }
            });
        },
    });
    return (
        <React.Fragment>
            <ToastContainer style={{ zIndex: 99999 }} />
            <div className='main'>
                <div className='container'>
                    <div className='users'>
                        <div className='add-new-form'>
                            <div className='signup-form'>
                                <h2 className="form-title">Log in</h2>
                                <Form onSubmit={formik.handleSubmit}>
                                    <>
                                        <Form.Group controlId="" className='input-text'>
                                            <Form.Label>User name</Form.Label>
                                            <Form.Control type="name" name='username' value={formik.values.username} onChange={formik.handleChange} isInvalid={!!formik.errors?.username} placeholder="" />
                                        </Form.Group>

                                        <Form.Group controlId="" className='input-text'>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type={showPassword ? 'input' : 'password'}
                                                name='password'
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors?.password}
                                                placeholder=""

                                            />
                                            <div className='eye_button'>
                                                {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />

                                                </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" onClick={() => { togglePasswordVisibility() }} className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                    </svg>}
                                            </div>
                                        </Form.Group>
                                        <div className='buttons'>
                                            {loading ?
                                                <Svgloader />
                                                :
                                                <button className='button' type='submit'>Login</button>
                                            }
                                            <>
                                                <Link className="forgot_password mt-4" to='/forgot-password'>Forgot password?</Link>
                                            </>
                                        </div>
                                    </>

                                </Form>
                            </div>
                            <div className='signup-image'>
                                <figure>
                                    <img src={loginimg} alt="login" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Login