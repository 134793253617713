import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/admin-panel.scss';
import './assets/css/styles.scss';
import { BrowserRouter as Router, Switch, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import AdminPanel from './pages/admin-panel';
import Login from './components/login/login';
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import ForgotPassword from './components/forgot-password/forgot-password';
import ResetPassword from './components/reset-password/reset-password';
import Filter from './components/filter';
import ErrorPage from './components/404-Page/404-page';


const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route path='/*' element={<AdminPanel />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password/' element={<ForgotPassword />} />
          <Route path='/password/reset/password/:id/:token' element={<ResetPassword />} />
          <Route path='/filter' element={<Filter />} />
          {/* <Route path='/*' element={<ErrorPage />} /> */}
        </Routes>
      </Wrapper>
    </Router>
  )
}
export default App;
