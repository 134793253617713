import { CREATE_PACKAGES_URL, DELETE_PACKAGES_URL, LIST_PACKAGES_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listPackages = (search,page,limit) => onResponse => {
    try {
        axiosInstance.get(LIST_PACKAGES_URL + '?search=' + search + '&page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createPackages = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_PACKAGES_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deletePackages = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_PACKAGES_URL, {
            data: { package_id: id }
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSinglePackage = (id) => onResponse => {
    try {
        axiosInstance.get(LIST_PACKAGES_URL + '?package_id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};