import { faAngleDown, faAngleLeft, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPackages, listSinglePackage } from '../../Redux/Packages/action';
import { addUsers, listSingleUser } from '../../Redux/User/action';
import Packages from './packages';
import Users from './users';

const AddNewPackage = (props) => {
    const [active, setActive] = useState("")
    const [disableBtn, setDisableBtn] = useState(false)
    const { edit, id } = props;
    useEffect(() => {
        if (id) {
            console.log('gfhdfhdf')
            listSinglePackage(id)((response) => {
                if (response && response.status) {
                    //   setData(response?.data?.rooms[0])
                    formik.setValues({
                        ...formik.values,
                        name: response?.data?.results[0].name,
                        rate: response?.data?.results[0].rate,
                        discount_rate: response?.data?.results[0].discount_rate,
                        limit: response?.data?.results[0].limit,
                        tax: response?.data?.results[0].tax,
                        details: response?.data?.results[0].details[0].details,
                    })
                }
            });
        }
    }, [id])
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),

        rate: Yup.number()
            .required('Required'),
        limit: Yup.number()
            .required('Required'),
        tax: Yup.number()
            .required('Required'),

    });
    const formik = useFormik({
        initialValues: {
            name: "",
            rate: "",
            discount_rate: "",
            limit: "",
            details: "",
            tax: "",

        },
        validationSchema,
        onSubmit: (values) => {
            setDisableBtn(true)
            console.log(values)

            let formData = new FormData();
            if (edit)
                formData.append('package_id', id);
            formData.append('name', values.name);
            formData.append('rate', values.rate);
            formData.append('discount_rate', values.discount_rate ? values.discount_rate : 0);
            formData.append('limit', values.limit);
            formData.append('tax', values.tax);
            formData.append('details', values.details);

            createPackages({ formData })((response) => {
                if (response && response.status) {
                    setActive('packages')
                }
                else {
                    setDisableBtn(false)
                }
            });

        },
    });
    return (
        <Fragment>
            {active === "packages" ? <Packages show='packages' /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("packages")}
                            style={{ marginRight: "12px", }}
                        />Packages</h2>
                        <button className='button' onClick={() => setActive("packages")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="name"
                                    name='name'
                                    isValid={formik.touched.name && !formik.errors.name}
                                    isInvalid={!!formik.errors.name}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Rate</Form.Label>
                                <Form.Control
                                    type="number"
                                    name='rate'
                                    min="0"
                                    isValid={formik.touched.rate && !formik.errors.rate}
                                    isInvalid={!!formik.errors.rate}
                                    value={formik.values.rate}
                                    onKeyDown={(event) => {
                                        if (event.key === '-' || event.key === 'e' || event.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const regex = /^\d+(\.\d{0,2})?$/;
                                        console.log(regex.test(input))
                                        if (input === '' || regex.test(input)) {
                                            formik.setFieldValue('rate', input);
                                        }
                                    }}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.rate}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Discount rate</Form.Label>
                                <Form.Control
                                    type="number"
                                    name='discount_rate'
                                    min="0"
                                    value={formik.values.discount_rate}
                                    onKeyDown={(event) => {
                                        if (event.key === '-' || event.key === 'e' || event.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const regex = /^\d+(\.\d{0,2})?$/;

                                        if (input === '' || regex.test(input)) {
                                            formik.handleChange(event);
                                        }
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Tax</Form.Label>
                                <Form.Control
                                    type="number"
                                    name='tax'
                                    min="0"
                                    value={formik.values.tax}
                                    isInvalid={!!formik.errors.tax}
                                    onKeyDown={(event) => {
                                        if (event.key === '-' || event.key === 'e' || event.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const regex = /^[0-9\b]+$/;

                                        if (input === '' || regex.test(input)) {
                                            formik.handleChange(event);
                                        }
                                    }}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.tax}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Limit</Form.Label>
                                <Form.Control
                                    type="number"
                                    name='limit'
                                    min="0"
                                    isValid={formik.touched.limit && !formik.errors.limit}
                                    isInvalid={!!formik.errors.limit}
                                    value={formik.values.limit}
                                    onKeyDown={(event) => {
                                        if (event.key === '-' || event.key === 'e' || event.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const regex = /^[0-9\b]+$/;

                                        if (input === '' || regex.test(input)) {
                                            formik.handleChange(event);
                                        }
                                    }}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.limit}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Details</Form.Label>
                                <Form.Control type="text" name='details' value={formik.values.details} onChange={formik.handleChange} placeholder="" />
                            </Form.Group>




                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit'>Save</button>
                                <button className='button clear-btn' onClick={formik.resetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewPackage