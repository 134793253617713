import { faAngleDown, faAngleLeft, faAngleUp, faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { createOurDesigners, deleteOurDesignersPortfolio, listSingleDesigners } from '../../Redux/OurDesigners/action';
import { createPackages, listSinglePackage } from '../../Redux/Packages/action';
import { addUsers, listSingleUser } from '../../Redux/User/action';
import OurDesigners from './our-designers';
import imageToBase64 from 'image-to-base64/browser';
import Packages from './packages';
import Users from './users';
import { toast, ToastContainer } from 'react-toastify';

var images = [{
    title: '',
    image: {}
}]
const AddNewDesigner = (props) => {
    const [active, setActive] = useState("")
    const formref = useRef()
    const [disableBtn, setDisableBtn] = useState(false)
    const imageInput = useRef();
    // const [images, setImages] = useState([{
    //     title: '',
    //     image: ''
    // }])
    const [update, SetUpdate] = useState(false)
    const { edit, id } = props;
    useEffect(() => {
        if (id) {
            listSingleDesigners(id)((response) => {
                if (response && response.status) {
                    //   setData(response?.data?.rooms[0])
                    formik.setValues({
                        ...formik.values,
                        name: response?.data?.results[0].name,
                        about: response?.data?.results[0].about,
                        qualification: response?.data?.results[0].qualification,
                        experience: response?.data?.results[0].experience,
                        imageurl: response?.data?.results[0].ourimage,
                        portfolios: response?.data?.results[0].portfolio,
                        // details: response?.data?.results[0].details[0].details,
                    })
                }
            });
        }
    }, [id])
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        about: Yup.string()
            .required('Required'),
        qualification: Yup.string()
            .required('Required'),
        experience: Yup.string()
            .required('Required'),
        imageurl: Yup.string()
            .required('Required'),




    });
    const formik = useFormik({
        initialValues: {
            name: "",
            about: "",
            qualification: "",
            experience: "",
            image: "",
            imageurl: "",
            portfolios: []

        },
        validationSchema,
        onSubmit: (values) => {
            setDisableBtn(true)
            console.log(images)

            let formData = new FormData();
            if (edit)
                formData.append('designer_id', id);
            formData.append('name', values.name);
            formData.append('about', values.about);
            formData.append('qualification', values.qualification);
            formData.append('experience', values.experience);
            if (images[0]?.title && images[0]?.title != '')
                formData.append('portfolio', JSON.stringify(images?.map(image => image)));
            if (values.image && values.image != '')
                formData.append('ourimage', values.image);
            // console.log(JSON.stringify(images));
            createOurDesigners({ formData })((response) => {
                if (response && response.status) {
                    images = [{
                        title: '',
                        image: {}
                    }]
                    setActive('designs')
                }
                else {
                    setDisableBtn(false)
                }
            });

        },
    });

    const handleImageCount = () => {
        images.push({
            title: '',
            image: ''
        })
        SetUpdate(!update)
    }

    const handleDeleteImage = (index) => {
        images.splice(index, 1);
        SetUpdate(!update);
    };

    const changeImageTitle = (e, index) => {
        console.log(images[index])
        if (images[index])
            images[index].title = e.target.value
        else {
            images[index] = {};
            images[index].title = e.target.value
        }

    }
    const changeImageValue = (e, index) => {
        imageToBase64(URL.createObjectURL(e.target.files[0])).then(
            (response) => {
                console.log(`data:image/jpeg;base64,${response}`);
                if (images[index])
                    images[index].image = `data:image/jpeg;base64,${response}`
                else {
                    images[index] = {};
                    images[index].image = `data:image/jpeg;base64,${response}`
                }
                // files.push(`data:image/jpeg;base64,${response}`)
            }
        )
            .catch(
                (error) => {
                    console.log(error); // Logs an error if there was one
                }
            )

        console.log(images);

    }
    const handleChangeImage = (e) => {
        formik.setValues({ ...formik.values, image: e.target.files[0], imageurl: URL.createObjectURL(e.target.files[0]) })
    }
    const deleteSelectedImage = (e) => {
        formik.setValues({ ...formik.values, image: '', imageurl: '' })
    }
    const openFileUploader = () => {
        imageInput.current.click();
    }
    const resetForm = () => {
        formref.current.reset();

        formik.resetForm();
    }
    const deleteCurrentPortfolio = (portfolio) => {
        deleteOurDesignersPortfolio(portfolio)((response) => {
            if (response && response.status) {
                listSingleDesigners(id)((response) => {
                    if (response && response.status) {
                        //   setData(response?.data?.rooms[0])
                        formik.setValues({
                            ...formik.values,
                            name: response?.data?.results[0].name,
                            about: response?.data?.results[0].about,
                            qualification: response?.data?.results[0].qualification,
                            experience: response?.data?.results[0].experience,
                            imageurl: response?.data?.results[0].ourimage,
                            portfolios: response?.data?.results[0].portfolio,
                            // details: response?.data?.results[0].details[0].details,
                        })
                        toast.success("Portfolio deleted successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                });
            }
            else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
    }
    return (
        <Fragment>
            {active === "designs" ? <OurDesigners /> : (
                <div className='users'>
                    <ToastContainer style={{ zIndex: 99999 }} />
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("designs")}
                            style={{ marginRight: "12px", }}
                        />Add new designer</h2>
                        <button className='button' onClick={() => setActive("designs")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit} id='designers-form' ref={formref}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="name"
                                    name='name'
                                    isValid={formik.touched.name && !formik.errors.name}
                                    isInvalid={!!formik.errors.name}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>About</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='about'
                                    isValid={formik.touched.about && !formik.errors.about}
                                    isInvalid={!!formik.errors.about}
                                    value={formik.values.about}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.about}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Qualification</Form.Label>
                                <Form.Control type="text" name='qualification' value={formik.values.qualification} onChange={formik.handleChange} placeholder="" />
                            </Form.Group>


                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Experience</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='experience'
                                    isValid={formik.touched.experience && !formik.errors.experience}
                                    isInvalid={!!formik.errors.experience}
                                    value={formik.values.experience}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.experience}</Form.Control.Feedback>

                            </Form.Group>
                            <div className='add-new-product'>
                                <div className='add-new'>
                                    <div className='add-new-file'>
                                        <input type="file" className='d-none' ref={imageInput} onChange={(e) => handleChangeImage(e)} />
                                        <Form.Group className='input-text mt-2'>
                                            <Form.Label>User Image</Form.Label>
                                        </Form.Group>
                                        <div className='file-images d-flex flex-row align-items-center justify-content-start flex-wrap ' style={{ overFlowX: 'scroll' }}>

                                            <div className='ms-2' >
                                                <img src={formik?.values?.imageurl ? formik?.values?.imageurl : require("../../assets/images/upload.png")} alt="upload" onClick={() => openFileUploader()} />
                                                <button type='button'><FontAwesomeIcon icon={faXmarkCircle} onClick={() => deleteSelectedImage()} /></button>
                                                {formik.errors?.imageurl &&
                                                    <div className='text-danger'>* Required</div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {formik.values?.portfolios?.length ?
                                <Form.Group controlId="" className='input-text mt-5'>
                                    <Form.Label>Current Portfolio</Form.Label>
                                    <div className='file-images d-flex flex-row mt-2'>
                                        {formik.values?.portfolios?.map((portfolio, index) =>
                                            <Fragment>
                                                <div style={{ position: 'relative' }}>
                                                    <img src={portfolio?.image ? portfolio?.image : require("../../assets/images/upload.png")} style={{ borderRadius: '10px' }} alt="upload" height='150' width='150' className='ms-2 mb-2 mt-2' />
                                                    <button type='button' className='custom-button'><FontAwesomeIcon icon={faXmarkCircle} onClick={() => deleteCurrentPortfolio(portfolio?.id)} /></button>
                                                    <div className='text-center'>{portfolio?.title}</div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </Form.Group>
                                :
                                <></>
                            }
                            <Form.Group controlId="" className='input-text mt-2'>
                                <Form.Label>Portfolio</Form.Label>
                                {images?.map((image, index) =>
                                    <div className='d-flex flex-row mb-3' key={index}>
                                        <Form.Control
                                            type="text"
                                            name={`images[${index}].title`}
                                            placeholder=""
                                            onChange={(e) => changeImageTitle(e, index)}
                                            required={!edit}
                                        />
                                        <input
                                            type="file"
                                            name={`images[${index}].image`}
                                            className='ms-2'
                                            placeholder=""
                                            onChange={(e) => changeImageValue(e, index)}
                                            required={!edit}
                                        />
                                        {index !== 0 && (
                                            <FontAwesomeIcon
                                                className='ms-3'
                                                icon={faTrash}
                                                onClick={() => handleDeleteImage(index)}
                                                style={{ marginTop: "12px", cursor: "pointer" }}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className='d-flex gap-3'>
                                    <div className='btn btn-primary' onClick={() => handleImageCount()}>Add new</div>
                                </div>
                            </Form.Group>






                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit'>Save</button>
                                <button className='button clear-btn' onClick={resetForm} >Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewDesigner