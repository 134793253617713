import React, { useState, Fragment, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCopy, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import Item from "../item";
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { deleteUser, listUsers } from '../../Redux/User/action';
import { useFormik } from 'formik';
import AddNew from './add-new-package'
import { deletePackages, listPackages } from '../../Redux/Packages/action';
import AddNewRole from './add-new-role';
import { deleteRole, getRoles } from '../../Redux/Roles/action';
import { checkUserPermission } from '../../helpers';
import DataTable from 'react-data-table-component';
import { useMemo } from 'react';
import datatableCustomStyles from '../../pages/DatatableCustomStyles';
import Svgloader from '../SvgLoader';



const Roles = () => {

    const [active, setActive] = useState("")
    const [roles, setRoles] = useState([])
    const [search, setSearch] = useState('')
    const [loader, setLoader] = useState(true)
    const [editId, setEditId] = useState('')
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(10);



    useEffect(() => {
        getRoleDatas()
    }, [page,limit])

    const getRoleDatas = () => {
        getRoles(page,limit)((response) => {
            if (response && response.status) {
                setRoles(response?.data?.results)
                setTotalRows(response?.data?.count)
                setLoader(false)
            }
        });
    }


    const DeletePopup = () => {
        return (
            <div className='popup-box'>
                <div className='delete-box'>
                    <FontAwesomeIcon icon={faXmark} onClick={() => setActive(!active)} />
                    <img src={require("../../assets/images/delete.png")} alt="delete" />
                    <h2>You are about to delete a Role</h2>
                    <p>Are you sure? This action cannot be undone.</p>
                    <div className='buttons'>
                        <button onClick={() => setActive(!active)}>Cancel</button>
                        <button className='delete' onClick={() => onClickDelete()}>Delete</button>
                    </div>
                </div>
            </div>
        )
    }

    const onClickDelete = () => {
        deleteRole(editId)((response) => {
            if (response && response.status) {
                setActive('list')
                getRoleDatas()
            }
        });
    }
    const columns = useMemo(
        () => [
            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) =>
                    <div className='info'>
                        <div className='' >
                            <div style={{ minWidth: '100px' }}>
                                <h3>{row?.name}</h3>
                            </div>
                        </div>
                    </div>,
                sortable: true,
            },
            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div>
                        {checkUserPermission('Edit Roles') &&
                            <div className='option-icons-package justify-content-end'>
                                <button type='button' onClick={() => { setEditId(row?.id); setActive('Edit') }}><FontAwesomeIcon icon={faCopy} /></button>
                                <button type='button' onClick={() => { setEditId(row?.id); setActive('Delete') }}><FontAwesomeIcon icon={faTrash} /></button>
                            </div>
                        }
                    </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },

        ],
        [],
    );


    return (
        <Fragment>
      {loader ?
        <div style={{ height: '100vh' }}>
          <Svgloader />
        </div>
        :
        <Fragment>
            {active === "Delete" && (<DeletePopup />)}
            {active === "AddNew" ? (<AddNewRole />) : (
                active === "Edit" ? (<AddNewRole edit={true} id={editId} />) :
                    <div className='users'>
                        <div className='user-head'>
                            <h2>Roles</h2>
                            {checkUserPermission('Edit Roles') &&
                                <button className='button' onClick={() => setActive("AddNew")}>Add New</button>
                            }
                        </div>
                       
                        <div className='admin-item-table'>
                            <div className='table-data'>
                                <DataTable
                                    fixedHeader={true}
                                    columns={columns}
                                    data={roles}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={(page) => setPage(page)}
                                    onChangeRowsPerPage={(newPerPage) => setLimit(newPerPage)}
                                    customStyles={datatableCustomStyles}
                                />
                            </div>
                        </div>
                    </div>
            )
            }
        </Fragment>
    }
    </Fragment>
    )
}

export default Roles