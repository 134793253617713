import { CREATE_BROCHURE_URL, LIST_BROCHURE_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const createBrochure = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_BROCHURE_URL,props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};
export const listBrochure = () => onResponse => {
    try {
        axiosInstance.get(LIST_BROCHURE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
