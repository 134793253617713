import { CREATE_FAQ_URL, DELETE_FAQ_URL, LIST_FAQ_URL, LIST_SINGLE_FAQ_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const createFaq = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_FAQ_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listFaq = (search,page,limit) => onResponse => {
    try {
        axiosInstance.get(LIST_FAQ_URL + '?search=' + search + '&page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSingleFaq = (id) => onResponse => {
    try {
        axiosInstance.get(LIST_SINGLE_FAQ_URL + '?faq=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteFaq = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_FAQ_URL, {data:{faq_id : id}})
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};