import React, { useState, Fragment, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEdit, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import AddNewRole from './add-new-role';
import { deleteRole, getRoles } from '../../Redux/Roles/action';
import { checkUserPermission } from '../../helpers';
import DataTable from 'react-data-table-component';
import { useMemo } from 'react';
import datatableCustomStyles from '../../pages/DatatableCustomStyles';
import { deletePropertyType, getPropertyTypes } from '../../Redux/PropertyType/action';
import AddNewPropertyType from './add-new-property-type';
import AddNewTestimonial from './add-new-testimonial';



const PropertyType = () => {

  const [active, setActive] = useState("")
  const [propertyType, setPropertyType] = useState([])
  const [editId, setEditId] = useState('')
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(10);



  useEffect(() => {
    getPropertyTypeDatas()
  }, [page, limit])

  const getPropertyTypeDatas = () => {
    getPropertyTypes(page, limit)((response) => {
      if (response && response.status) {
        setPropertyType(response?.data?.results)
        setTotalRows(response?.data?.count)
      }
    });
  }


  const DeletePopup = () => {
    return (
      <div className='popup-box'>
        <div className='delete-box'>
          <FontAwesomeIcon icon={faXmark} onClick={() => setActive(!active)} />
          <img src={require("../../assets/images/delete.png")} alt="delete" />
          <h2>You are about to delete a Property Type</h2>
          <p>Are you sure? This action cannot be undone.</p>
          <div className='buttons'>
            <button onClick={() => setActive(!active)} >Cancel</button>
            <button className='delete' onClick={() => onClickDelete()}>Delete</button>
          </div>
        </div>
      </div>
    )
  }

  const onClickDelete = () => {
    deletePropertyType(editId)((response) => {
      if (response && response.status) {
        setActive('list')
        getPropertyTypeDatas()
      }
    });
  }
  const columns = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        selector: row => row.name,
        cell: (row) =>
          <div className='info'>
            <div className='' >
              <div style={{ minWidth: '100px' }}>
                <h3>{row?.name}</h3>
              </div>
            </div>
          </div>,
        sortable: true,
      },
      {
        id: 'edit',
        name: 'Actions',

        cell: (row) =>
          <div>
            <div className='option-icons-package justify-content-end'>
              {checkUserPermission('Edit Property Type') &&
                <button type='button' onClick={() => { setEditId(row?.id); setActive('Edit') }}><FontAwesomeIcon icon={faCopy} /></button>
              }
              {checkUserPermission('Delete Property Type') &&
                <button type='button' onClick={() => { setEditId(row?.id); setActive('Delete') }}><FontAwesomeIcon icon={faTrash} /></button>
              }
            </div>
          </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },

    ],
    [],
  );


  return (
    <Fragment>
      {active === "Delete" && (<DeletePopup />)}
      {active === "AddNew" ? (<AddNewPropertyType />) : (
        active === "Edit" ? (<AddNewPropertyType edit={true} id={editId} />) :
          <div className='users'>
            <div className='user-head'>
              <h2>Property Types</h2>
              {checkUserPermission('Create Property Type') &&
                <button className='button' onClick={() => setActive("AddNew")}>Add New</button>
              }
            </div>

            <div className='admin-item-table'>
              <div className='table-data'>
                <DataTable
                  fixedHeader={true}
                  columns={columns}
                  data={propertyType}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangePage={(page) => setPage(page)}
                  onChangeRowsPerPage={(newPerPage) => setLimit(newPerPage)}
                  customStyles={datatableCustomStyles}
                />
              </div>
            </div>
          </div>
      )
      }
    </Fragment>
  )
}

export default PropertyType