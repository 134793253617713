import { ACTIVE_INACTIVE_USERS, CREATE_USER_URL, DELETE_USER_URL, LIST_USER_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listUsers = (is_admin, rm_admin, page, limit) => onResponse => {
    try {
        axiosInstance.get(LIST_USER_URL + '?is_admin=' + is_admin + '&rm_admin=' + rm_admin + '&page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSingleUser = (user_id) => onResponse => {
    try {
        axiosInstance.get(LIST_USER_URL + '?user_id=' + user_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const addUsers = (props) => onResponse => {
    console.log('hre');
    try {
        axiosInstance.post(CREATE_USER_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteUser = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_USER_URL, {
            data: { user_id: id }
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const statusUser = (props) => onResponse => {
    try {
        axiosInstance.post(ACTIVE_INACTIVE_USERS, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};