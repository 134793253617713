import { useFormik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { userResetpassword } from '../../Redux/Auth/action';
import loginimg from '../../assets/images/signup-image.jpg';


const ResetPassword = () => {
    const { id, token } = useParams()
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        // title: Yup.string()
        //   .required('Required'),

        // subscription: Yup.string()
        //   .required('Required'),

    });
    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",

        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values)
            let formData = new FormData();

            formData.append('new_password', values.new_password);
            formData.append('confirm_password', values.confirm_password);
            formData.append('uid', id);
            formData.append('token', token);

            userResetpassword({ formData })((response) => {
                if (response && response.status) {
                    console.log(response?.data);
                    localStorage.setItem('token', response?.data?.token)
                    localStorage.setItem('refresh_token', response?.data?.refresh)
                    localStorage.setItem('user', JSON.stringify(response?.data?.user))
                    localStorage.setItem('permissions', JSON.stringify(response?.data?.user?.permissions))
                    navigate('/login')
                }
            });
        },
    });
    return (
        <React.Fragment>
            <div className='main'>
                <div className='container'>
                    <div className='users'>
                        <div className='add-new-form'>
                            <div className='signup-form'>
                                <h2 className="form-title">Create new password</h2>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group controlId="" className='input-text'>
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" name='new_password' value={formik.values.new_password} onChange={formik.handleChange} placeholder="" />
                                    </Form.Group>
                                    <Form.Group controlId="" className='input-text'>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" name='confirm_password' value={formik.values.confirm_password} onChange={formik.handleChange} placeholder="" />
                                    </Form.Group>
                                    <div className='buttons'>
                                        <button className='button' type='submit'>Send</button>
                                    </div>

                                </Form>
                            </div>
                            <div className='signup-image'>
                                <figure>
                                    <img src={loginimg} alt="login" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ResetPassword