import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationDetails: (state, action) => {
      return action.payload;
    },
  },
});

export const { setNotificationDetails } = notificationSlice.actions;

// export const selectUserName = (state) => state.user.name;
// export const selectUserEmail = (state) => state.user.email;
// export const selectUserPhoto = (state) => state.user.photo;

export default notificationSlice.reducer;
