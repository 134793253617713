export const checkUserPermission = (check) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if(user?.is_superuser)
  {
    return true;
  }
  if (check == 'No Permission') {
    return true;
  }
  var permissions = JSON.parse(localStorage.getItem('permissions'))
  var data = permissions?.find((element) => {
    return element == check;
  })
  // console.log(data)
  if (data && data != undefined) {
    return true;
  }
  else {

    return false;
  }
}