import { faAngleDown, faAngleLeft, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { createFaq, listSingleFaq } from '../../Redux/Faq/action';
import { createPackages, listSinglePackage } from '../../Redux/Packages/action';
import { addUsers, listSingleUser } from '../../Redux/User/action';
import Faq from './faq';
import Packages from './packages';
import Users from './users';

const AddNewFaq = (props) => {
    const [active, setActive] = useState("")
    const { edit, id } = props;
    const [disableBtn, setDisableBtn] = useState(false)
    useEffect(() => {
        if (id) {
            listSingleFaq(id)((response) => {
                if (response && response.status) {
                    formik.setValues({
                        ...formik.values,
                        question: response?.data?.question,
                        answer: response?.data?.answer,

                    })
                }
            });
        }
    }, [id])
    const validationSchema = Yup.object().shape({
        question: Yup.string()
            .required('Required'),

        answer: Yup.string()
            .required('Required'),

    });
    const formik = useFormik({
        initialValues: {
            question: "",
            answer: "",


        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values)
            setDisableBtn(true)
            let formData = new FormData();
            if (edit)
                formData.append('faq', id);
            formData.append('question', values.question);
            formData.append('answer', values.answer);

            createFaq({ formData })((response) => {
                if (response && response.status) {
                    setActive('faq')
                }
                else {
                    setDisableBtn(false)
                }
            });
        },
        validate: values => {
            const errors = {};

            if (values.answer.length > 1000) {
                errors.answer = 'Exceeded maximum character limit (1000 characters)';
            }

            return errors;
        },

    });


    return (
        <Fragment>
            {active === "faq" ? <Faq /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("faq")}
                            style={{ marginRight: "12px", }}
                        />FAQ</h2>
                        <button className='button' onClick={() => setActive("faq")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Question</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='question'
                                    isValid={formik.touched.question && !formik.errors.question}
                                    isInvalid={!!formik.errors.question}
                                    value={formik.values.question}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.question}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Answer</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='answer'
                                    isValid={formik.touched.answer && !formik.errors.answer}
                                    isInvalid={!!formik.errors.answer}
                                    value={formik.values.answer}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <span className='faq-limit'>Max limit 1000 characters</span>
                                <Form.Control.Feedback type="invalid">{formik.errors.answer}</Form.Control.Feedback>
                            </Form.Group>




                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit'>Save</button>
                                <button className='button clear-btn' onClick={formik.resetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewFaq