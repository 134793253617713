import React, { lazy, Suspense, useEffect, useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faStickyNote, faUserFriends, faGear, faCreditCard, faPenAlt, faThLarge, faFileImage, faPenFancy, faPenNib, faUserGroup, faUserAlt, faQuestion, faQuoteLeft, faUsers, faTasksAlt, faGroupArrowsRotate, faDatabase, faBuilding, faQuestionCircle, faCogs, faPhone, faPuzzlePiece, faDirections } from "@fortawesome/free-solid-svg-icons";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import { checkUserPermission } from "../helpers"
import Testimonial from "../components/admin-panel/testimonials";
import OurDesigners from "../components/admin-panel/our-designers";
import ContactUs from "../components/admin-panel/contact-us";
import Settings from "../components/admin-panel/settings";
import 'react-toastify/dist/ReactToastify.css';
import Solutions from "../components/admin-panel/solutions";
import Svgloader from "../components/SvgLoader";
import Directions from "../components/admin-panel/directions.js";

const Header = lazy(() => import('../components/header'));
const Dashboard = lazy(() => import('../components/admin-panel/dashboard'));
const Products = lazy(() => import('../components/admin-panel/products'));
const Categories = lazy(() => import('../components/admin-panel/categories'));
const Purchase = lazy(() => import('../components/admin-panel/purchase'));
const Reports = lazy(() => import('../components/admin-panel/reports'));
const Customers = lazy(() => import('../components/admin-panel/customers'));
const MasterSettings = lazy(() => import('../components/admin-panel/master-settings'));
const Designs = lazy(() => import('../components/admin-panel/designs'));
const Packages = lazy(() => import('../components/admin-panel/packages'));
const Faq = lazy(() => import('../components/admin-panel/faq.js'));
const MainDesigns = lazy(() => import('../components/admin-panel/main-designs'));
const Users = lazy(() => import('../components/admin-panel/users'));
const Typology = lazy(() => import('../components/admin-panel/typology'));
const Roles = lazy(() => import('../components/admin-panel/roles'));
const Groups = lazy(() => import('../components/admin-panel/groups'));
const PropertyType = lazy(() => import('../components/admin-panel/property-type'));
const Viewallnotification = lazy(() => import('../components/viewallnotification'));


const AdminPanel = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('token') || !localStorage.getItem('permissions')) {
      navigate('/login')
    }



  }, [])
  const MenuList = [
    {
      permission: 'No Permission',
      label: "Dashboard",
      icon: faHome,
      linkList: "/",
    },
    {
      permission: 'View Category',
      label: "Categories",
      icon: faThLarge,
      linkList: "/categories",
    },
    {
      permission: 'View Products',
      label: "Products",
      icon: faFileImage,
      linkList: "/products",
    },
    {
      permission: 'View Property Type',
      label: "Property Type",
      icon: faBuilding,
      linkList: "/property-type",
    },
    {
      permission: 'View Directions',
      label: "Directions",
      icon: faDirections,
      linkList: "/directions",
    },
    {
      permission: 'View Plans',
      label: "Main Designs",
      icon: faPenFancy,
      linkList: "/main-design",
    },
    {
      permission: 'View Typology',
      label: "Typology",
      icon: faPenNib,
      linkList: "/typology",
    },
    {
      permission: 'View Packages',
      label: "Packages",
      icon: faGear,
      linkList: "/packages",
    },
    {
      permission: 'View Designs',
      label: "Designs",
      icon: faPenAlt,
      linkList: "/designs",
    },
    {
      permission: 'View Purchase',
      label: "Purchase",
      icon: faCreditCard,
      linkList: "/purchase",
    },
    {
      permission: 'View Reports',
      label: "Reports",
      icon: faStickyNote,
      linkList: "/reports",
    },
    {
      permission: 'View Customers',
      label: "Customers",
      icon: faUserFriends,
      linkList: "/customers",
    },
    {
      permission: 'View Our Designers',
      label: "Our Designers",
      icon: faUserAlt,
      linkList: "/our-designers",
    },
    {
      permission: 'View Testimonial',
      label: "Testimonials",
      icon: faQuoteLeft,
      linkList: "/testimonial",
    },
    {
      permission: 'View Roles',
      label: "Roles",
      icon: faTasksAlt,
      linkList: "/roles",
    },
    {
      permission: 'View Groups',
      label: "Groups",
      icon: faGroupArrowsRotate,
      linkList: "/groups",
    },
    {
      permission: 'View Users',
      label: "Users",
      icon: faUsers,
      linkList: "/users",
    },
    {
      permission: 'View Master Settings',
      label: "Master",
      icon: faDatabase,
      linkList: "/master-settings",
    },
    {
      permission: 'View Solutions',
      label: "Solutions",
      icon: faPuzzlePiece,
      linkList: "/solutions",
    },
    {
      permission: 'View Faq',
      label: "FAQ",
      icon: faQuestionCircle,
      linkList: "/faq",
    },
    {
      permission: 'View Contact Us',
      label: "Contact us",
      icon: faPhone,
      linkList: "/contact-us",
    },
    {
      permission: 'View Settings',
      label: "Settings",
      icon: faCogs,
      linkList: "/settings",
    }
  ];
  return (
    <div className='admin-panel'>
      <Header />
      <Tab.Container id="left-tabs-example" defaultActiveKey="dashboard">
        <div>

          <div className="panel-nav-icons">
            <div className="hide-scrollbar" >
              {MenuList.map((page) =>
                checkUserPermission(page?.permission) &&
                <Nav variant="pills">
                  <Nav.Item onClick={() => navigate(page.linkList)} className={window.location.pathname == page.linkList ? "active" : ""}>
                    <Nav.Link ><FontAwesomeIcon icon={page.icon} /><span>{page.label}</span></Nav.Link>
                  </Nav.Item>
                </Nav>
              )}
            </div>

          </div>

          <div className='panel-content'>


            <Suspense
              fallback={
                <div style={{ height: '100vh' }}>
                  <Svgloader />
                </div>
              }
            >
              <Switch>
                <Route path="/" element={<Dashboard />} />
                <Route path="/products" element={<Products />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/designs" element={<Designs />} />
                <Route path="/main-design" element={<MainDesigns />} />
                <Route path="/property-type" element={<PropertyType />} />
                <Route path="/directions" element={<Directions />} />
                <Route path="/typology" element={<Typology />} />
                <Route path="/purchase" element={<Purchase />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/our-designers" element={<OurDesigners />} />
                <Route path="/master-settings" element={<MasterSettings />} />
                <Route path="/users" element={<Users />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/testimonial" element={<Testimonial />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/solutions" element={<Solutions />} />
                <Route path='/viewallnotification' element={<Viewallnotification />} />

              </Switch>
            </Suspense>
          </div>
        </div>
      </Tab.Container>
    </div>
  )
}
export default AdminPanel