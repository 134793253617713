import { faEye, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import { checkUserPermission } from '../../helpers'
import { createBrochure, listBrochure } from '../../Redux/Settings/action'

const Settings=() => {
    const [state, setState]=useState({
        brochure: null
    })
    const [brochure, setBrochure]=useState({})
    useEffect(() => {
        listBrochure()((response) => {
            if (response&&response.status) {
                setBrochure(response?.data?.results[0])
            }
        });

    }, [])
    const saveBrochure=() => {
        if (state.brochure&&state.brochure!=null) {
            let formData=new FormData();
            formData.append('file', state.brochure)
            createBrochure({ formData })((response) => {
                if (response&&response.status) {
                    listBrochure()((response) => {
                        if (response&&response.status) {
                            setBrochure(response?.data?.results[0])
                        }
                    });
                    toast.success("Brochure updated successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setState({brochure: null})
                }
                else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        }
        else {
            toast.warning("Select a file first", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const viewBrochure=() => {
        let tag=document.createElement('a');
        tag.href=brochure?.file;
        tag.target='_blank';
        tag.click();
    }
    return (
        <div className='settings mt-2'>
            <ToastContainer style={{ zIndex: 99999 }} />
            <div className='row'>
                <div className='col-12'>
                    <h2>Settings</h2>
                </div>
            </div>
            <div className='row d-flex align-items-center mt-20'>
                <div className='col-lg-4'>
                    <div>Brochure:</div>
                </div>
                <div className='col-lg-4'>
                    <div>
                        {/* <Form.Control 
                    type="file" 
                    className='fileBtn'
                     onChange={(e) => setState({ ...state, brochure: e.target.files[0] })} 
                     placeholder='Brochure' 
                      /> */}

                        <Form.Control
                            type="file"
                            className='fileBtn'
                            onChange={(e) => {
                                const selectedFile=e.target.files[0];
                                if (selectedFile&&selectedFile.type==='application/pdf') {
                                    setState({ ...state, brochure: selectedFile });
                                } else {
                                    toast.warning("Please select a PDF file", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            placeholder='Brochure'
                            accept=".pdf"
                        />

<span className='pdf-text'>Upload PDF file only</span>

                    </div>
                </div>
                <div className='col-lg-2'>
                    {checkUserPermission('Edit Settings')&&
                        <button type='button' onClick={saveBrochure} className='settings-btn'>Update</button>
                    }
                </div>
                <div className='col-lg-2'>
                    <button type='button' className='eye-btn'> <div onClick={viewBrochure} ><FontAwesomeIcon icon={faEye} /></div></button>

                </div>
            </div>
        </div>
    )
}

export default Settings