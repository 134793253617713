import { faAngleLeft, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPropertyTypes, listSinglePropertyType } from '../../Redux/PropertyType/action';
import PropertyType from './property-type';
import { createDirections, listSingleDirection } from '../../Redux/Directions/actions';
import Directions from './directions';

const AddNewDirectionType = (props) => {

    const [active, setActive] = useState("")
    const { edit, id } = props;
    const [disableBtn, setDisableBtn] = useState(false)
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
    });

    useEffect(() => {
        if (id) {
            listSingleDirection(id)((response) => {
                if (response && response.status) {
                    //   setData(response?.data?.rooms[0])
                    formik.setValues({
                        ...formik.values,
                        title: response?.data?.results[0].title,
                    })
                }
            });
        }
    }, [id])

    const formik = useFormik({
        initialValues: {
            title: "",
        },
        validationSchema,
        onSubmit: (values) => {
            setDisableBtn(true)
            let formData = new FormData();
            if (edit && id)
                formData.append('direction_id', id)
            formData.append('title', values?.title)
            createDirections({ formData })((response) => {
                if (response && response.status) {
                    setActive('direction')
                }
                else {
                    setDisableBtn(false)
                }
            });
        },
    });


    return (
        <Fragment>
            {active === "direction" ? <Directions /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("direction")}
                            style={{ marginRight: "12px", }}
                        />Property Type</h2>
                        <button className='button' onClick={() => setActive('direction')}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='title'
                                    isValid={formik.touched.title && !formik.errors.title}
                                    isInvalid={!!formik.errors.title}
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                            </Form.Group>
                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit' >Save</button>
                                <button className='button clear-btn' onClick={formik.resetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewDirectionType