import { CREATE_DIRECTIONS_URL, DELETE_DIRECTIONS_URL, GET_DIRECTIONS_DROPDOWN_URL, GET_DIRECTIONS_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getDirections = (page, limit) => onResponse => {
    try {
        axiosInstance.get(GET_DIRECTIONS_URL + '?page=' + page + '&limit=' + limit)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createDirections = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_DIRECTIONS_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteDirections = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_DIRECTIONS_URL, { data: { direction_id: id } })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listAllDirections = () => onResponse => {
    try {
        axiosInstance.get(GET_DIRECTIONS_DROPDOWN_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSingleDirection = (direction_id) => onResponse => {
    try {
        axiosInstance.get(GET_DIRECTIONS_URL + '?direction_id=' + direction_id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};