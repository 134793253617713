import { CREATE_OUR_DESIGNERS_URL, DELETE_OUR_DESIGNERS_PORTFOLIO_URL, DELETE_OUR_DESIGNERS_URL, LIST_OUR_DESIGNERS_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listOurDesigners = (page,limit) => onResponse => {
    try {
        axiosInstance.get(LIST_OUR_DESIGNERS_URL +  '?page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSingleDesigners = (id) => onResponse => {
    try {
        axiosInstance.get(LIST_OUR_DESIGNERS_URL +  '?designer_id=' + id )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOurDesigners = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_OUR_DESIGNERS_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteOurDesigners = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_OUR_DESIGNERS_URL, {data: {designer_id: id}})
            .then((response) => {
                // console.log(response)    
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteOurDesignersPortfolio = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_OUR_DESIGNERS_PORTFOLIO_URL, {data: {portfolio_id: id}})
            .then((response) => {
                // console.log(response)    
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};