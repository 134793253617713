import { faAngleDown, faAngleLeft, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { createFaq, listSingleFaq } from '../../Redux/Faq/action';
import { createSolution, listSingleSolutions } from '../../Redux/Solutions/action';

import Faq from './faq';
import Solutions from './solutions';


const AddNewSolution = (props) => {
    const [active, setActive] = useState("")
    const { edit, id } = props;
    const [disableBtn, setDisableBtn] = useState(false)
    useEffect(() => {
        if (id) {
            listSingleSolutions(id)((response) => {
                if (response && response.status) {
                    formik.setValues({
                        ...formik.values,
                        title: response?.data?.results[0]?.title,
                        link: response?.data?.results[0]?.link,

                    })
                }
            });
        }
    }, [id])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),

        link: Yup.string().required("Required")

    });
    const formik = useFormik({
        initialValues: {
            title: "",
            link: "",


        },
        validationSchema,
        onSubmit: (values) => {
            setDisableBtn(true)
            console.log(values)
            let formData = new FormData();
            if (edit)
                formData.append('solution_id', id);
            formData.append('title', values.title);
            formData.append('link', values.link);

            createSolution({ formData })((response) => {
                if (response && response.status) {
                    setActive('solution')
                }
                else {
                    setDisableBtn(false)
                }
            });
        },
    });
    return (
        <Fragment>
            {active === "solution" ? <Solutions /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("solution")}
                            style={{ marginRight: "12px", }}
                        />Solution</h2>
                        <button className='button' onClick={() => setActive("solution")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='title'
                                    isInvalid={!!formik.errors.title}
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Link</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='link'
                                    isInvalid={!!formik.errors.link}
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.link}</Form.Control.Feedback>
                            </Form.Group>




                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit'>Save</button>
                                <button className='button clear-btn' onClick={formik.resetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewSolution