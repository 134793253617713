const datatableCustomStyles = {
    rows: {
        style: {
            minHeight: '50px', // override the row height
            fontSize: '0.9em',
            fontColor: '#212529 !important',
            width: '100%',
            paddingLeft: '10px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '10px', // override the cell padding for head cells
            paddingRight: '5px',
            textTransform: "uppercase",
            fontSize: '1.2em',
            fontWeight: '600',
            fontColor: '#6c757d !important',
            opacity: 1,
            minHeight: "50px",
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
            height: '70px'
        },
    },
    pagination: {
        style: {
            color: '#000',
            fontSize: '13px',
            minHeight: '56px',
            backgroundColor: '#fff',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#f2f2f2',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: '0px',
            cursor: 'pointer',
            transition: '0.4s',
            color: '#000',
            fill: '#000',
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
                color: '#e2e2e2',
                fill: '#e2e2e2',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: '#f2f2f2',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#f5f5f5',
            },
        },
    },
};


export default datatableCustomStyles;
