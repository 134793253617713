import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCopy, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import Item from "../item";
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { deleteUser, listUsers, statusUser } from '../../Redux/User/action';
import { useFormik } from 'formik';
import AddNew from './add-new-user'
import { checkUserPermission } from '../../helpers';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from '../../pages/DatatableCustomStyles';
import Svgloader from '../SvgLoader';


const Users = () => {

  const [active, setActive] = useState("")
  const [users, setUsers] = useState([])
  const [editId, setEditId] = useState('')
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(10);
  useEffect(() => {
    setLoading(true)
    listUsers(false, false, page, limit)((response) => {
      if (response && response.status) {
        setUsers(response?.data?.results)
        setTotalRows(response?.data?.count)
        setLoading(false)
        setLoader(false)
      }
    });
  }, [page, limit])



  const DeletePopup = () => {
    return (
      <div className='popup-box'>
        <div className='delete-box'>
          <FontAwesomeIcon icon={faXmark} onClick={() => setActive(!active)} />
          <img src={require("../../assets/images/delete.png")} alt="delete" />
          <h2>You are about to delete a user</h2>
          <p>Are you sure? This action cannot be undone.</p>
          <div className='buttons'>
            <button onClick={() => setActive(!active)}>Cancel</button>
            <button className='delete' onClick={() => onClickDelete()}>Delete</button>
          </div>
        </div>
      </div>
    )
  }
  const onClickDelete = () => {
    deleteUser(editId)((response) => {
      if (response && response.status) {
        listUsers(false, false, page, limit)((response) => {
          if (response && response.status) {
            setUsers(response?.data?.results)
            setTotalRows(response?.data?.count)
            setLoading(false)
            setActive('list')
          }
        });
      }
    });
  }

  const onClickStatusUser = (id) => {
    let formData = new FormData();
    formData.append('user_id', id);
    statusUser({ formData })((response) => {
      if (response && response.status) {
        listUsers(false, false, page, limit)((response) => {
          if (response && response.status) {
            setUsers(response?.data?.results)
            setTotalRows(response?.data?.count)
            setLoading(false)
            setActive('list')
          }
        });
      }
    });
  }

  const columns = useMemo(
    () => [

      {
        id: 'user',
        name: 'User',
        selector: row => row.name,
        cell: (row) =>
          <div className='info'>
            <div className='' >
              <img src={row.image} height={100} alt="pic" />
              <div style={{ minWidth: '100px' }}>
                <h3>{row?.name}</h3>
              </div>
            </div>
          </div>,
        sortable: true,
        // wrap: true,
        // grow: 3,

      },

      {
        id: 'email',
        name: 'Email',
        selector: row => row.email,
        cell: (row) => <div className=''>
          {row.email}
        </div>,
        sortable: true,
        // left: true,
        // wrap: true,
        // grow: 0.3,


      },
      {
        id: 'phone',
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
        left: true,
        wrap: true,
        // grow: 0.3,


      },
      {
        id: 'username',
        name: 'Username',
        selector: row => row.username,
        sortable: true,
        left: true,
        wrap: true,
        // grow: 0.4,


      },




      {
        id: 'edit',
        name: 'Actions',

        cell: (row) =>
          <div>
            {checkUserPermission('Edit Users') &&
              <div className='option-icons-user'>
                <button type='button' onClick={() => { setEditId(row?.id); setActive('Edit') }}><FontAwesomeIcon icon={faCopy} /></button>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" checked={row?.is_active} onClick={() => onClickStatusUser(row?.id)} />
                </div>
                {/* <button type='button' onClick={() => { setEditId(row?.id); setActive('Delete') }}><FontAwesomeIcon icon={faTrash} /></button> */}
              </div>
            }
          </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },

    ],
    [],
  );
  return (
    <Fragment>
      {loader ?
        <div style={{ height: '100vh' }}>
          <Svgloader />
        </div>
        :
        <Fragment>
          {active === "Delete" && (<DeletePopup />)}
          {active === "AddNew" ? (<AddNew />) : (
            active === "Edit" ? (<AddNew edit={true} id={editId} />) :
              <div className='users'>
                <div className='user-head'>
                  <h2>Users</h2>
                  {checkUserPermission('Edit Users') &&
                    <button className='button' onClick={() => setActive("AddNew")}>Add New</button>
                  }
                </div>
                <div className='admin-item-table'>
                  <div className='table-data'>
                    <DataTable
                      fixedHeader={true}
                      columns={columns}
                      data={users}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangePage={(page) => setPage(page)}
                      onChangeRowsPerPage={(newPerPage) => setLimit(newPerPage)}
                      customStyles={datatableCustomStyles}
                    />
                  </div>
                </div>
              </div>
          )}
        </Fragment>
      }
    </Fragment>
  )
}

export default Users