import { CREATE_ROLE_URL, DELETE_ROLE_URL, DOWNLOAD_REPORT_URL, GET_ALL_PERMISSIONS_URL, GET_ROLES_URL, GET_SINGLE_ROLE_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getAllPermissions = () => onResponse => {
    try {
        axiosInstance.get(GET_ALL_PERMISSIONS_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createRole = (name, permissions) => onResponse => {
    try {
        axiosInstance.post(CREATE_ROLE_URL, { name, permissions })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const editRole = (role,name, permissions) => onResponse => {
    try {
        axiosInstance.post(CREATE_ROLE_URL, {role, name, permissions })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getRoles = (page,limit) => onResponse => {
    try {
        axiosInstance.get(GET_ROLES_URL + '?page=' +page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listRoles = () => onResponse => {
    try {
        axiosInstance.get(GET_ROLES_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleRole = (role) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_ROLE_URL + '?role=' + role)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteRole = (role) => onResponse => {
    try {
        axiosInstance.delete(DELETE_ROLE_URL,{data:{role}})
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};