import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { userForgotPassword } from '../../Redux/Auth/action';
import { useNavigate } from 'react-router-dom';
import loginimg from '../../assets/images/signup-image.jpg';
import { toast, ToastContainer } from 'react-toastify';
import Svgloader from '../SvgLoader';


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Required'),
        // title: Yup.string()
        //   .required('Required'),

        // subscription: Yup.string()
        //   .required('Required'),

    });


    
    const formik = useFormik({
        initialValues: {
            email: "",

        },
        validationSchema,
        onSubmit: async (values) => {
            if (!formik.isValid) {
                return; // Don't proceed if the form has validation errors
            }

            // Your submit logic here
            setLoading(true);
            let formData = new FormData();
            formData.append('user_email', values.email);

            userForgotPassword({ formData })((response) => {
                if (response && response.status) {
                    console.log(response?.data);
                    setLoading(false);
                    toast.success("Email sent successfully. Please check your email.", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    formik.resetForm();
                } else {
                    setLoading(false);
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    return (
        <React.Fragment>
            <ToastContainer style={{ zIndex: 99999 }} />
            <div className='main'>
                <div className='container'>
                    <div className='users'>
                        <div className='add-new-form'>
                            <div className='signup-form'>
                                <h2 className="form-title">Forgot password</h2>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group controlId="" className='input-text'>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name='email' value={formik.values.email} onChange={formik.handleChange} placeholder="" />
                                    </Form.Group>
                                    <div className='buttons'>
                                        {loading ?
                                            <Svgloader />
                                            :
                                            <button className='button' type='submit'>Send</button>
                                        }
                                    </div>

                                </Form>
                            </div>
                            <div className='signup-image'>
                                <figure>
                                    <img src={loginimg} alt="login" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ForgotPassword;