import { CREATE_GROUP_URL, DELETE_GROUP_URL, GET_GROUPS_URL, GET_SINGLE_GROUP_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const createGroup = (name, roles) => onResponse => {
    try {
        axiosInstance.post(CREATE_GROUP_URL, { name, roles })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const editGroup = (group,name, roles) => onResponse => {
    try {
        axiosInstance.post(CREATE_GROUP_URL, { group,name, roles })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getGroups = (page,limit) => onResponse => {
    try {
        axiosInstance.get(GET_GROUPS_URL + '?page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listGroups = () => onResponse => {
    try {
        axiosInstance.get(GET_GROUPS_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleGroup = (group) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_GROUP_URL + '?group=' + group)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteGroup = (group) => onResponse => {
    try {
        axiosInstance.delete(DELETE_GROUP_URL,{data:{group}})
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};