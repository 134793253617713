import { fa9, faAngry, faArrowsToEye, faCopy, faEye, faTrash, faVirus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { checkUserPermission } from '../../helpers';
import datatableCustomStyles from '../../pages/DatatableCustomStyles'
import { listContactUs } from '../../Redux/ContactUs/action';
import Svgloader from '../SvgLoader';

const ContactUs = () => {
    const [messages, setMessages] = useState([])
    const [selected, setSelected] = useState({})
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(true)
    const [totalRows, setTotalRows] = useState(10);
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        getContactUsDatas()
    }, [page, limit])

    const getContactUsDatas = () => {
        setLoading(true)
        listContactUs(search, page, limit)((response) => {
            if (response && response.status) {
                setMessages(response?.data?.results)
                setTotalRows(response?.data?.count)
                setLoading(false)
                setLoader(false)
            }
            else {
                setLoading(false)
                setLoader(false)
            }
        });
    }

    const columns = useMemo(
        () => [

            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) =>
                    <div className='info'>
                        <div className='' >
                            <div style={{ minWidth: '100px' }}>
                                <h3>{row?.name}</h3>
                            </div>
                        </div>
                    </div>,
                sortable: true,
                // wrap: true,
                // grow: 3,

            },

            {
                id: 'email',
                name: 'E-mail',
                selector: row => row.email,
                cell: (row) => <div className=''>
                    {row.email}
                </div>,
                sortable: true,
                // left: true,
                // wrap: true,
                // grow: 0.3,


            },
            {
                id: 'type',
                name: 'Type',
                selector: row => row.type_name,
                cell: (row) => <div className=''>
                    {row.type_name}
                </div>,
                sortable: true,
                // left: true,
                // wrap: true,
                // grow: 0.3,


            },

            {
                id: 'edit',
                name: 'Action',

                cell: (row) =>
                    <div>
                        <div className='option-icons-package'>
                            <button type='button' onClick={() => { setSelected(row); setShow(true); }}><FontAwesomeIcon icon={faEye} /></button>
                            {/* <button type='button' onClick={() => { setSelected(row); setShow(true); }}><FontAwesomeIcon icon={faTrash} /></button> */}

                        </div>
                    </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },

        ],
        [],
    );


    const handleClose = () => {
        setShow(false);
    }
    return (
        <Fragment>
            {loader ?
                <div style={{ height: '100vh' }}>
                    <Svgloader />
                </div>
                :
                <Fragment>
                    <div className='user-head'>
                        <h2>Contact us</h2>
                       
                    </div>
                    <div className='admin-item-table'>
                        <div className='table-data'>
                            <DataTable
                                fixedHeader={true}
                                columns={columns}
                                data={messages}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={(page) => setPage(page)}
                                onChangeRowsPerPage={(newPerPage) => setLimit(newPerPage)}
                                customStyles={datatableCustomStyles}
                            />
                        </div>
                        <Modal show={show} onHide={handleClose} style={{ marginTop: '122px' }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        placeholder="Name"
                                        value={selected?.name}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        disabled={true}
                                        placeholder="Email"
                                        value={selected?.email}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        placeholder="Phone"
                                        value={selected?.phone_number}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" disabled={true} value={selected?.message} rows={5} />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

export default ContactUs