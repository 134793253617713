import { CREATE_TESTIMONIALS_URL, DELETE_TESTIMONIALS_URL, LIST_TESTIMONIALS_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listTestimonials = (page, limit) => onResponse => {
    try {
        axiosInstance.get(LIST_TESTIMONIALS_URL + '?page=' + page + '&limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createTestimonial = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_TESTIMONIALS_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteTestimonial = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_TESTIMONIALS_URL, { data: { testimonial_id: id } })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const listSingleTestimonial = (id) => onResponse => {
    try {
        axiosInstance.get(LIST_TESTIMONIALS_URL + '?testimonial_id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
