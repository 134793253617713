import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import CheckboxTree from 'react-checkbox-tree';
import * as Yup from 'yup';
import { listSinglePackage } from '../../Redux/Packages/action';
import { createRole, editRole, getAllPermissions, getSingleRole } from '../../Redux/Roles/action';
import Roles from './roles';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { toast, ToastContainer } from 'react-toastify';

const AddNewRole = (props) => {
    const [active, setActive] = useState("")
    const [nodes, setNodes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false)
    const [expanded, setExpanded] = useState([]);
    const { edit, id } = props;
    useEffect(() => {
        if (id) {
            getSingleRole(id)((response) => {
                if (response && response.status) {
                    //   setData(response?.data?.rooms[0])
                    formik.setValues({
                        ...formik.values,
                        name: response?.data?.role?.name,

                    })
                    setChecked(response?.data?.role?.permissions)
                }
            });
        }
    }, [id])
    useEffect(() => {

        getAllPermissions()((response) => {
            if (response && response.status) {
                setNodes(response.data?.permissions)
            }
        });

    }, [])
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),

    });
    const formik = useFormik({
        initialValues: {
            name: "",


        },
        validationSchema,
        onSubmit: (values) => {
            if (checked && checked[0]) {
                setDisableBtn(true)
                console.log(values)
                if (id) {

                    editRole(id, values.name, checked)((response) => {
                        if (response && response.status) {
                            setActive('roles')
                        }
                        else {
                            setDisableBtn(false)
                        }
                    });
                }
                else {
                    createRole(values.name, checked)((response) => {
                        if (response && response.status) {
                            setActive('roles')
                        }
                        else {
                            setDisableBtn(false)
                        }
                    });
                }
            }
            else {
                toast.warning("please select a permission", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        },
    });
    const onResetForm = () => {
        formik.resetForm()
        setChecked([])
    }
    return (
        <Fragment>
            <ToastContainer style={{ zIndex: 99999 }} />
            {active === "roles" ? <Roles /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("roles")}
                            style={{ marginRight: "12px", }}
                        />Roles</h2>
                        <button className='button' onClick={() => setActive("roles")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='name'
                                    isValid={formik.touched.name && !formik.errors.name}
                                    isInvalid={!!formik.errors.name}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <CheckboxTree
                                nodes={nodes}
                                checked={checked}
                                expanded={expanded}
                                onCheck={checked => setChecked(checked)}
                                onExpand={expanded => setExpanded(expanded)}
                                icons={{
                                    check: <i className="bi bi-check2-square" />,
                                    uncheck: <span className="bi bi-square" />,
                                    halfCheck: <span className="bi bi-dash-square" />,
                                    expandClose: <span className="bi bi-chevron-right" />,
                                    expandOpen: <span className="bi bi-chevron-down" />,
                                    expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                    collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                    parentClose: <span className="bi bi-folder" />,
                                    parentOpen: <span className="bi-folder2-open" />,
                                    leaf: <span className="bi bi-file-earmark" />,
                                }}
                            />





                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit'>Save</button>
                                <button className='button clear-btn' onClick={onResetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewRole