import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import notificationReducer from "../features/notification/notificationSlice";

export default configureStore({
  reducer: {
    notifications: notificationReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
