import { faAngleDown, faAngleLeft, faAngleUp, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPackages, listSinglePackage } from '../../Redux/Packages/action';
import { createTestimonial, listSingleTestimonial } from '../../Redux/Testimonial/action';
import { addUsers, listSingleUser } from '../../Redux/User/action';
import Packages from './packages';
import Testimonial from './testimonials';
import Users from './users';

const AddNewTestimonial = (props) => {
    const [active, setActive] = useState("")
    const [disableBtn, setDisableBtn] = useState(false)
    const { edit, id } = props;
    const imageInput = useRef();
    useEffect(() => {
        if (id) {
            listSingleTestimonial(id)((response) => {
                if (response && response.status) {
                    //   setData(response?.data?.rooms[0])
                    formik.setValues({
                        ...formik.values,
                        name: response?.data?.results[0].name,
                        description: response?.data?.results[0].description,
                        imageurl: response?.data?.results[0].image,

                    })
                }
            });
        }
    }, [id])
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),

        description: Yup.string()
            .required('Required'),
        imageurl: Yup.string()
            .required('Required'),


    });
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            image: '',
            imageurl: ''
        },
        validationSchema,
        onSubmit: (values) => {
            setDisableBtn(true)
            console.log(values)

            let formData = new FormData();
            if (edit)
                formData.append('testimonial_id', id);
            formData.append('name', values.name);
            formData.append('description', values.description);
            if (values.image)
                formData.append('image', values.image)
            createTestimonial({ formData })((response) => {
                if (response && response.status) {
                    setActive('testimonial')
                }
                else {
                    setDisableBtn(false)
                }
            });

        },
    });
    const handleChangeImage = (e) => {
        formik.setValues({ ...formik.values, image: e.target.files[0], imageurl: URL.createObjectURL(e.target.files[0]) })
    }
    const deleteSelectedImage = (e) => {
        formik.setValues({ ...formik.values, image: '', imageurl: '' })
    }
    const openFileUploader = () => {
        imageInput.current.click();
    }
    return (
        <Fragment>
            {active === "testimonial" ? <Testimonial /> : (
                <div className='users'>
                    <div className='user-head'>
                        <h2><FontAwesomeIcon icon={faAngleLeft} onClick={() => setActive("testimonial")}
                            style={{ marginRight: "12px", }}
                        />Testimonial</h2>
                        <button className='button' onClick={() => setActive("testimonial")}>List</button>
                    </div>
                    <div className='add-new-form'>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="name"
                                    name='name'
                                    isValid={formik.touched.name && !formik.errors.name}
                                    isInvalid={!!formik.errors.name}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="" className='input-text'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='description'
                                    isValid={formik.touched.description && !formik.errors.description}
                                    isInvalid={!!formik.errors.description}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    placeholder=""
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                            </Form.Group>


                            <div className='add-new-product'>
                                <div className='add-new'>
                                    <div className='add-new-file'>
                                        <input type="file" className='d-none' ref={imageInput} onChange={(e) => handleChangeImage(e)} />
                                        <Form.Group className='input-text mt-2'>
                                            <Form.Label>User Image</Form.Label>
                                        </Form.Group>
                                        <div className='file-images d-flex flex-row align-items-center justify-content-start flex-wrap ' style={{ overFlowX: 'scroll' }}>

                                            <div className='ms-2' >
                                                <img src={formik?.values?.imageurl ? formik?.values?.imageurl : require("../../assets/images/upload.png")} alt="upload" onClick={() => openFileUploader()} />
                                                <button type='button'><FontAwesomeIcon icon={faXmarkCircle} onClick={() => deleteSelectedImage()} /></button>
                                                {formik.errors?.imageurl &&
                                                    <div className='text-danger'>* Required</div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='buttons'>
                                <button className='button' disabled={disableBtn} type='submit'>Save</button>
                                <button className='button clear-btn' onClick={formik.resetForm}>Clear</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default AddNewTestimonial