import { CREATE_SOLUTION_URL, GET_SINGLE_SOLUTION_URL, DELETE_SOLUTION_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listSingleSolutions = (id) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_SOLUTION_URL + '?solution_id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createSolution = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_SOLUTION_URL,props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSolutions = (search,page,limit) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_SOLUTION_URL  )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deleteSolution = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_SOLUTION_URL, { data: { solution_id: id } })
            .then((response) => {
                console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};