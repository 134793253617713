import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";

const Options = () => {
  const [expanded1, setExpanded1] = useState(false)
  const [expanded2, setExpanded2] = useState(false)
  const [expanded3, setExpanded3] = useState(false)
  const [expanded4, setExpanded4] = useState(false)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [custom, setCustom] = useState(false);

  return (
    <div>
      {/* By Date */}
      <div className='filter-options'>
        <div>
          <h4 onClick={() => setExpanded1(!expanded1)}>By Date</h4>
          <button onClick={() => setExpanded1(!expanded1)}>
            {expanded1 ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </button>
        </div>
        {expanded1 &&
          <div className='check-options'>
            <div className='options'>
              <div>
                <label>Last Week</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
              <div>
                <label>Last month</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
              <div>
                <label>Custom</label>
                <input type="radio" name="radio" onChange={() => { setCustom(true) }} />
              </div>
              {custom && (
                <div className='datepicker'>
                  <div className='start-date'>
                    <label>From</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    <FontAwesomeIcon icon={faCalendar} style={{ color: "#999999" }} />
                  </div>
                  <div className='end-date'>
                    <label>To</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    <FontAwesomeIcon icon={faCalendar} style={{ color: "#999999" }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </div>

      {/* By Status */}
      <div className='filter-options'>
        <div>
          <h4 onClick={() => setExpanded2(!expanded2)}>By Status</h4>
          <button onClick={() => setExpanded2(!expanded2)}>
            {expanded2 ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </button>
        </div>
        {expanded2 &&
          <div className='check-options'>
            <div className='options'>
              <div>
                <label>Success</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
              <div>
                <label>Pending</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
              <div>
                <label>Failed</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
            </div>
          </div>
        }
      </div>

      {/* By Design */}
      <div className='filter-options'>
        <div>
          <h4 onClick={() => setExpanded3(!expanded3)}>By Subscription</h4>
          <button onClick={() => setExpanded3(!expanded3)}>
            {expanded3 ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </button>
        </div>
        {expanded3 &&
          <div className='check-options'>
            <div className='options'>
              <div>
                <label>Free</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
              <div>
                <label>Premium</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
            </div>
          </div>
        }
      </div>

      {/* By Customer */}
      <div className='filter-options'>
        <div>
          <h4 onClick={() => setExpanded4(!expanded4)}>By Customer</h4>
          <button onClick={() => setExpanded4(!expanded4)}>
            {expanded4 ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </button>
        </div>
        {expanded4 &&
          <div className='check-options'>
            <div className='options'>
              <div>
                <label>A - Z ascending</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
              <div>
                <label>Z - A descending</label>
                <input type="radio" name="radio" onChange={() => { }} />
              </div>
            </div>
          </div>
        }
      </div>
      <div className='filter-btns'>
        <button className='button clear-bnt'>Clear</button>
        <button className='button'>Apply</button>
      </div>
    </div>
  )
}

const Filter = ({ handleState }) => {
  return (
    <div className='filter'>
      <div className='filter-info'>
        <div className='head'>
          <h2>Filter</h2>
          <FontAwesomeIcon icon={faXmark} onClick={handleState} />
        </div>
        <div>
          <Options />
        </div>
      </div>
    </div>
  )
}

export default Filter