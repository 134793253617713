import { FORGOT_PASSWORD_URL, LOGIN_URL, LOGOUT_URL, RESET_PASSWORD_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const userLogin = (props) => onResponse => {
    try {
        axiosInstance.post(LOGIN_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const userLogout = (props) => onResponse => {
    try {
        axiosInstance.post(LOGOUT_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const userForgotPassword = (props) => onResponse => {
    try {
        axiosInstance.post(FORGOT_PASSWORD_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const userResetpassword = (props) => onResponse => {
    try {
        axiosInstance.post(RESET_PASSWORD_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};