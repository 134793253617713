import { CREATE_PROPERTY_TYPE_URL, DELETE_PROPERTY_TYPE_URL, GET_PROPERTY_TYPE_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getPropertyTypes = (page, limit) => onResponse => {
    try {
        axiosInstance.get(GET_PROPERTY_TYPE_URL + '?page=' + page + '&limit=' + limit)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createPropertyTypes = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_PROPERTY_TYPE_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deletePropertyType = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_PROPERTY_TYPE_URL, { data: { property_id: id } })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listAllPropertyTypes = () => onResponse => {
    try {
        axiosInstance.get(GET_PROPERTY_TYPE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSinglePropertyType = (property_id) => onResponse => {
    try {
        axiosInstance.get(GET_PROPERTY_TYPE_URL + '?property_id=' + property_id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};